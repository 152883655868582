
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { getSupport } from '@/services/api';

export default defineComponent({
  setup() {
    const supportId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const data = ref();

    onMounted(async() => {
      const res = await getSupport({ supportId });
      data.value = res.data;
    });

    return {
      data,
      page

    };
  }
});
